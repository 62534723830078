import React from 'react';
import "animate.css/animate.min.css"
import SBDStyle from './SBoardDescription.module.css'
import SBPatching from '../assets/sb_patching.mp4'
import SBPresets from '../assets/sb_presets.mp4'
import Acousma from '../assets/Acousma_panel.png'
import Charger from '../assets/Charger_panel.png'
import Sweep from '../assets/Sweep_panel.png'
import Pressure from '../assets/Pressure_panel.png'
import LFO from '../assets/LFO_panel.png'
import Gain from '../assets/Gain_panel.png'
import Palette from '../assets/Palette_panel.png'
import EnvFollower from '../assets/EnvFollower_panel.png'
import Cover from '../assets/sb_cover.jpg'
import DragAndDrop from '../assets/DragDrop.mp4'
import SBoardRelatedCards from "./SBoardRelatedCards"
import SB_Footer from './SB_Description_Footer'

class SBoardDescription extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={SBDStyle.container}>
                <div style={{color: '#0f0f0f', background: '#f0f0f0'}}>
                    <div className={SBDStyle.titleContainer} style={{color: '#0f0f0f'}}>
                        <h2>S-Board</h2>
                        <h3>Modular effect plugin - VST/AU/AAX/Standalone</h3>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <img src={Cover} className={SBDStyle.banner}/>
                        <div style={{height: "16px"}}/>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <p className={SBDStyle.slogan}>No <div style={{display: 'inline-block', color:'#008fff'}}>rules</div> here, only <div style={{display: 'inline-block', color:'#ff0099'}}>creativity</div></p>
                        <div style={{height: "30px"}}/>
                    </div>
                    <div className={SBDStyle.cartArea}>
                        <div className={SBDStyle.cartAreaContent}>
                            <div>
                                <p style={{color: '#6f747f', fontWeight: 800}}>Effect</p>
                                <p style={{fontSize: '1.8rem', textTransform: 'uppercase', fontWeight: 800}}>S-Board</p>
                                <div className={SBDStyle.currPrice}>US $79</div>
                            </div>            
                            <div className={SBDStyle.buttonWrapper} style={{textAlign:'center'}}>
                                <p style={{paddingTop: '0.6rem'}}>7 modules included</p>
                                <button onClick={this.props.addToCart} className={SBDStyle.addToCartButton}>Add To Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={SBDStyle.titleContainer}>
                        <h2>Overview</h2>
                    </div>
                    <div className={SBDStyle.content}>
                        <h4>Define your signal chain with no limits</h4>
                        <p>S-Board gives you unlimited sound-shaping possibilities. Build your own signal chain by simply drag & drop the units onto the board, then patch in some modifier units to turn a static sound into a dazzling soundscape. S-Board can become your go-to mixing channel strip or your secret sound design weapon, it's all up to you.</p>
                    </div>
                    <div className={SBDStyle.contentContainer}>
                        <div className={SBDStyle.uiImg} style={{padding: '1rem'}}>
                            <video loop muted autoPlay playsInline crossOrigin="anonymous" width={"85%"} id="patchVid">
                                <source src={DragAndDrop} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div style={{height: "80px"}}/>
                    <div className={SBDStyle.patchContainer}>
                        <div className={SBDStyle.content}>
                            <h4>Virtual patch cables</h4>
                            <p>The virtual patch cable system is what makes S-Board unique.  You'll find input jacks on many processor modules (like Acousma or Sweep) and some output jacks on the modifier units (Like LFO). When you patch a pair of them together with a virtual patch cable, some cool things happen - the parameter of the processor will be controlled by the modifier, which can lead to some fascinating, ever-changing sound. </p>
                        </div>
                        <div className={SBDStyle.patchVideo}>
                            <video loop muted autoPlay playsInline crossOrigin="anonymous" width={"250px"} id="patchVid">
                                <source src={SBPatching} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div style={{height: "120px"}}/>
                    <div className={SBDStyle.contentLong}>
                        <h4>Presets to get you start in no time</h4>
                        <p>S-Board can make highly complex sound, meanwhile still so easy to use. We have presets to get you started right away. Right-click on the board to choose a board-wide preset. ("What the hack" is our favorite, try that.) Right-Click on individual units to find their own presets. Once you get the hang of it, designing a great sound is as easy as turning the knobs and patching the cables.</p>
                    </div>
                    <div className={SBDStyle.contentContainer} style={{marginTop: '24px'}}>
                        <video loop muted autoPlay playsInline crossOrigin="anonymous" className={SBDStyle.uiImg} id="uiVid">
                            <source src={SBPresets} type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div>
                    <div className={SBDStyle.titleContainer}>
                        <h2>Modules</h2>
                        <h3>6 processors + 2 modifier, each packs a punch</h3>
                    </div>
                    <div style={{height: "30px"}}/>
                    <div className={SBDStyle.deviceConfigContainer}>
                        <div className={SBDStyle.columnContent}>
                            <div style={{margin: "auto", padding: '16px'}}>
                                <img src={Acousma} className={SBDStyle.unitPanel} />
                                <p style={{fontSize: '0.8rem', height: 'auto', margin: 0, padding: 0, textAlign: 'center', color: '#a0a0a0'}}>DELAY/MODULATION</p>
                                <h4 style={{textAlign: 'center'}}>Acousma</h4>
                                <p>An analog tape delay with endless possibilities. It gives you a wide range of delay time and feedback to dial in. Pair it with a modifier, tons of sensational effects await. </p>
                            </div>
                            <div style={{margin: "auto", padding: '16px'}}>
                                <img src={Charger} className={SBDStyle.unitPanel} />
                                <p style={{fontSize: '0.8rem', height: 'auto', margin: 0, padding: 0, textAlign: 'center', color: '#a0a0a0'}}>HARMONIC</p>
                                <h4 style={{textAlign: 'center'}}>Charger</h4>
                                <p>A vacuum tube inspired tone shaper. Saturate your sound for extra color and warmth, or distort it relentlessly. Tweak this beast with caution. </p>
                            </div>
                        </div>
                    </div>
                    <div className={SBDStyle.deviceConfigContainer}>
                        <div className={SBDStyle.columnContent}>
                            <div style={{margin: "auto", padding: '16px'}}>
                                <img src={Sweep} className={SBDStyle.unitPanel} />
                                <p style={{fontSize: '0.8rem', height: 'auto', margin: 0, padding: 0, textAlign: 'center', color: '#a0a0a0'}}>FILTER</p>
                                <h4 style={{textAlign: 'center'}}>Sweep</h4>
                                <p>A classic ladder filter emulation. Do a nice and warm filter sweep, or pair it with an LFO for unpredictable auto-wah effects. Drive it a little, you'll love the warmth.</p>
                            </div>
                            <div style={{margin: "auto", padding: '16px'}}>
                                <img src={Pressure} className={SBDStyle.unitPanel} />
                                <p style={{fontSize: '0.8rem', height: 'auto', margin: 0, padding: 0, textAlign: 'center', color: '#a0a0a0'}}>DYNAMIC</p>
                                <h4 style={{textAlign: 'center'}}>Pressure</h4>
                                <p>A musical and versatile compressor with optional sidechain input. Its soft-knee design and unique gain reduction curve excel at tightening things ups and glue things together.</p>
                            </div>
                        </div>
                    </div>
                    <div className={SBDStyle.deviceConfigContainer}>
                        <div className={SBDStyle.columnContent}>
                            <div style={{margin: "auto", padding: '16px'}}>
                                <img src={Palette} className={SBDStyle.unitPanel} />
                                <p style={{fontSize: '0.8rem', height: 'auto', margin: 0, padding: 0, textAlign: 'center', color: '#a0a0a0'}}>EQ</p>
                                <h4 style={{textAlign: 'center'}}>Palette</h4>
                                <p>A colorful EQ module. Its adaptive curves react musically to the cutoff frequencies and the amount of your boost/cut. When pushed hard, Its tube amplification stage provides a warm and tube-y saturation instead of digital clipping.</p>
                            </div>
                            <div style={{margin: "auto", padding: '16px'}}>
                                <img src={Gain} className={SBDStyle.unitPanel} />
                                <p style={{fontSize: '0.8rem', height: 'auto', margin: 0, padding: 0, textAlign: 'center', color: '#a0a0a0'}}>UTILITY</p>
                                <h4 style={{textAlign: 'center'}}>Gain</h4>
                                <p>A utility for gain staging. Don't let its simplicity fool you, plug in a modifier to its gain makes it a tremolo, or pan and it becomes an auto panner. Capable little unit.</p>
                            </div>
                        </div>
                    </div>
                    <div className={SBDStyle.deviceConfigContainer}>
                        <div className={SBDStyle.columnContent}>
                            <div style={{margin: "auto", padding: '16px'}}>
                                <img src={LFO} className={SBDStyle.unitPanel} />
                                <p style={{fontSize: '0.8rem', height: 'auto', margin: 0, padding: 0, textAlign: 'center', color: '#a0a0a0'}}>MODIFIER</p>
                                <h4 style={{textAlign: 'center'}}>LFO</h4>
                                <p>A beloved modifier. It acts like a robotic hand tweaking knobs for you following certain patterns. Connect it to a unit, a static sound becomes dynamic, it's that simple.</p>
                            </div>
                            <div style={{margin: "auto", padding: '16px'}}>
                                <img src={EnvFollower} className={SBDStyle.unitPanel} />
                                <p style={{fontSize: '0.8rem', height: 'auto', margin: 0, padding: 0, textAlign: 'center', color: '#a0a0a0'}}>MODIFIER</p>
                                <h4 style={{textAlign: 'center'}}>EnvFollower</h4>
                                <p>A super versatile envelope follower, it extracts amplitude envelope and transforms it into CV, great for transient shaping, sidechain filtering, or other program-dependent effects.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height: '3rem'}}/>
                <SBoardRelatedCards currentItem="s-board"/>

                <div>
                    <div className={SBDStyle.titleContainer}>
                        <h2>In Action</h2>
                    </div>
                    <div className={SBDStyle.content}>
                        <h4>Transform an static arp</h4>
                        <p>Morph a synth arp with S-Board, see how the modifier works with the processors to transform the sound to another dimension, and how much fun you can get on S-Board.</p>
                    </div>
                    <div class={SBDStyle.videoContainer}>
                        <div class={SBDStyle.videoFrameContainer}>
                            <iframe width="100%" height="0" class={SBDStyle.videoFrame} src="https://www.youtube.com/embed/-qXy2Efr0Fw?rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div style={{height: '6rem'}}/>
                    <div className={SBDStyle.content}>
                        <h4>Mix with S-Board only</h4>
                        <p>When you need to get serious on a mixing session, S-Board is the one you can put on your track with confidence. The modular design and the nice-sounding units make it a channel strip like no others.</p>
                    </div>
                    <div class={SBDStyle.videoContainer}>
                        <div class={SBDStyle.videoFrameContainer}>
                            <iframe width="100%" height="0" class={SBDStyle.videoFrame} src="https://www.youtube.com/embed/wuiE2CNOfew?rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>

                <div style={{height: '96px'}} />
                <div>
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1177466410&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                    </div>
                </div>

                <SB_Footer getTrial={this.props.getTrial}/>
            </div>   
        )
    }
}

export default SBoardDescription